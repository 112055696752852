import {
  faCheckSquare,
  faCopy,
  faSquare,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import DataTable, { DataTableExportFunction } from 'components/DataTable';
import { IDataTableColumnDefinition } from 'components/DataTable/IDataTableColumnDefinition';
import { endOfDay, startOfDay } from 'date-fns/esm';
import {
  standardFormatDate,
  standardFormatLastDayOfMonthDate,
} from 'jec-common/lib/utils/date-utils';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  MasterChecklistItemSortBy,
  MasterChecklistTableDataDocument,
  MasterChecklistTableDataItemFragment,
  MasterChecklistTableDataQuery,
  MasterChecklistTableDataQueryVariables,
  SortDirection,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';
import { IMasterChecklistItemFilterValues } from './MasterChecklistItemFilters';
import { PlainText } from 'components/Form/PlainText';

export interface IMasterChecklistItemTableControl {
  filter: (values: IMasterChecklistItemFilterValues) => void;
  refresh: () => void;
  export: (args: { filename: string }) => Promise<void>;
}

interface IProps {
  controlRef?: React.MutableRefObject<
    IMasterChecklistItemTableControl | undefined
  >;
  onOpenItem: (id: number, clone?: boolean) => () => void;
  checkedJournalIds: string[];
  onItemCheckedChange: (journalId: string) => void;
}

export const MasterChecklistItemTable: React.FC<IProps> = (props: IProps) => {
  const pageSize = 20;
  const { client } = useApolloClient();

  const [totalItems, setTotalItems] = useState<number>(0);
  const [items, setItems] = useState<MasterChecklistTableDataItemFragment[]>(
    [],
  );

  const [sortBy, setSortBy] = useState<MasterChecklistItemSortBy>(
    MasterChecklistItemSortBy.JournalId,
  );
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Asc,
  );

  const [filterValues, setFilterValues] =
    useState<IMasterChecklistItemFilterValues>({});

  const [alertModal, setAlertModal] = useState<any>();
  const { apolloError } = useModalAlerts(setAlertModal);

  const fetch = async (options: {
    skip?: number;
    take?: number;
    sortBy: MasterChecklistItemSortBy;
    sortDirection: SortDirection;
  }) => {
    return client.query<
      MasterChecklistTableDataQuery,
      MasterChecklistTableDataQueryVariables
    >({
      query: MasterChecklistTableDataDocument,
      fetchPolicy: 'no-cache',
      variables: {
        filter: {
          ...filterValues,
          lastExecuted: {
            from: filterValues.lastExecuted?.from
              ? startOfDay(new Date(filterValues.lastExecuted?.from))
              : undefined,
            to: filterValues.lastExecuted?.to
              ? endOfDay(new Date(filterValues.lastExecuted?.to))
              : undefined,
          },
        },
        paginationOptions: {
          skip: options.skip,
          take: options.take,
        },
        sortOptions: {
          sortBy: options.sortBy,
          sortDirection: options.sortDirection,
        },
      },
    });
  };

  const fetchNextItemSet = (append = true, skip: number = items.length) => {
    fetch({
      skip,
      take: pageSize,
      sortBy,
      sortDirection,
    })
      .then((value) => {
        setTotalItems(value.data.masterChecklistItems.totalItems);
        setItems(
          append
            ? [...items, ...value.data.masterChecklistItems.items]
            : value.data.masterChecklistItems.items,
        );
      })
      .catch((reason) =>
        apolloError({
          error: reason,
        }),
      );
  };

  const refresh = () => {
    fetchNextItemSet(false, 0);
  };

  useEffect(refresh, [filterValues, sortBy, sortDirection]);

  const handleSort = (
    sortBy: MasterChecklistItemSortBy,
    sortDirection: SortDirection,
  ) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
  };

  let exportFunction: DataTableExportFunction<MasterChecklistTableDataItemFragment>;
  const handleExport = (args: { filename: string }) => {
    return fetch({ sortBy, sortDirection }).then((value) => {
      return exportFunction({
        items: value.data.masterChecklistItems.items,
        filename: args.filename,
      });
    });
  };

  if (props.controlRef) {
    props.controlRef.current = {
      filter: setFilterValues,
      refresh,
      export: handleExport,
    };
  }

  const handleCheckedChange = (journalId: string) => () => {
    props.onItemCheckedChange(journalId);
  };

  const isChecked = (journalId: string) => {
    return !!props.checkedJournalIds.find(
      (checkedJournalId) => checkedJournalId === journalId,
    );
  };

  const getYYYYMM = (args: { year: number; month: number }): number =>
    args.year * 100 + args.month;

  const currentYYYYMM: number = getYYYYMM({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });

  const columns: IDataTableColumnDefinition<
    MasterChecklistTableDataItemFragment,
    MasterChecklistItemSortBy
  >[] = [
    {
      dataFieldName: 'bulkUpdate',
      heading: 'Bulk Update',
      width: '5rem',
      cellClassName: () => 'text-center',
      render: (item) =>
        isChecked(item.journalId) ? (
          <FontAwesomeIcon
            icon={faCheckSquare}
            onClick={handleCheckedChange(item.journalId)}
          />
        ) : (
          <FontAwesomeIcon
            icon={faSquare}
            onClick={handleCheckedChange(item.journalId)}
          />
        ),
    },
    {
      dataFieldName: 'daysAfterClose',
      heading: 'Days After Close',
      width: '8rem',
      render: (item) => (
        <PlainText>{item.dueDaysAfterClose.toString()}</PlainText>
      ),
      excelExport: (item) => item.dueDaysAfterClose,
      sortBy: MasterChecklistItemSortBy.DueDaysAfterClose,
    },
    {
      dataFieldName: 'entity',
      heading: 'Entity',
      width: '7rem',
      render: (item) => item.kbsEntity.name,
      excelExport: (item) => item.kbsEntity.name,
      sortBy: MasterChecklistItemSortBy.Entity,
    },
    {
      dataFieldName: 'title',
      heading: 'Title',
      render: (item) => item.title,
      excelExport: (item) => item.title,
      sortBy: MasterChecklistItemSortBy.Title,
    },
    {
      dataFieldName: 'type',
      heading: 'Type of Entry',
      render: (item) => item.jeType.name,
      excelExport: (item) => item.jeType.name,
      sortBy: MasterChecklistItemSortBy.JeType,
    },
    {
      dataFieldName: 'journalId',
      heading: 'Journal ID',
      render: (item) => (
        <span
          className={'pointer primary-link'}
          onClick={props.onOpenItem(item.id)}
        >
          {item.journalId}{' '}
          {!!item.retireYear &&
            !!item.retireMonth &&
            getYYYYMM({ year: item.retireYear, month: item.retireMonth }) <=
              currentYYYYMM && <span className="text-danger">(retired)</span>}
        </span>
      ),
      excelExport: (item) => item.journalId,
      sortBy: MasterChecklistItemSortBy.JournalId,
    },
    {
      dataFieldName: 'group',
      heading: 'Group',
      render: (item) => item.kbsGroup.name,
      excelExport: (item) => item.kbsGroup.name,
      sortBy: MasterChecklistItemSortBy.Group,
    },
    {
      dataFieldName: 'frequency',
      heading: 'Frequency',
      render: (item) => item.jeFrequency.name,
      excelExport: (item) => item.jeFrequency.name,
      sortBy: MasterChecklistItemSortBy.Frequency,
    },
    {
      dataFieldName: 'responsible',
      heading: 'Responsible',
      render: (item) => item.responsibleUser.name,
      excelExport: (item) => item.responsibleUser.name,
      sortBy: MasterChecklistItemSortBy.Responsible,
    },
    {
      dataFieldName: 'ignoredUntil',
      heading: 'Ignored Until',
      width: '6rem',
      render: (item) =>
        item.ignoreUntilMonth !== undefined &&
        item.ignoreUntilMonth !== null &&
        item.ignoreUntilYear
          ? standardFormatLastDayOfMonthDate(
              item.ignoreUntilMonth,
              item.ignoreUntilYear,
            )
          : '',
      excelExport: (item) =>
        item.ignoreUntilMonth !== undefined &&
        item.ignoreUntilMonth !== null &&
        item.ignoreUntilYear
          ? standardFormatLastDayOfMonthDate(
              item.ignoreUntilMonth,
              item.ignoreUntilYear,
            )
          : '',
      sortBy: MasterChecklistItemSortBy.IgnoredUntil,
    },
    {
      dataFieldName: 'lastExecutionDate',
      heading: 'Last Execution Date',
      width: '11rem',
      render: (item) => standardFormatDate(item.lastExecutedDate),
      excelExport: (item) => standardFormatDate(item.lastExecutedDate),
      sortBy: MasterChecklistItemSortBy.LastExecution,
    },
    {
      dataFieldName: 'clone',
      heading: 'Clone',
      cellClassName: () => 'text-center',
      render: (item) => (
        <span className={'pointer'} onClick={props.onOpenItem(item.id, true)}>
          <FontAwesomeIcon icon={faCopy} />
        </span>
      ),
    },
  ];

  return (
    <>
      {alertModal}
      <InfiniteScroll
        dataLength={items.length}
        next={fetchNextItemSet}
        hasMore={items.length < totalItems}
        loader={<span>loading more checklist rows...</span>}
        endMessage={<span></span>}
      >
        <DataTable<
          MasterChecklistTableDataItemFragment,
          MasterChecklistItemSortBy
        >
          columns={columns}
          data={items}
          getDataItemId={(item) => item.id}
          sort={{ sortBy, sortDirection }}
          small={false}
          onSort={handleSort}
          exportRef={(fn) => {
            exportFunction = fn;
          }}
        ></DataTable>
      </InfiniteScroll>
    </>
  );
};

export default MasterChecklistItemTable;
