import DefaultClient from 'apollo-boost';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import { Page } from 'layouts/Page';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  CreateMasterChecklistItemDocument,
  CreateMasterChecklistItemMutation,
  CreateMasterChecklistItemMutationVariables,
  MasterChecklistItemDetailDocument,
  MasterChecklistItemDetailFragment,
  MasterChecklistItemDetailQuery,
  MasterChecklistItemDetailQueryVariables,
  MasterChecklistItemInput,
  UpdateMasterChecklistItemDocument,
  UpdateMasterChecklistItemMutation,
  UpdateMasterChecklistItemMutationVariables,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';
import {
  IMasterChecklistItemDetail,
  MasterChecklistItemDetail,
} from './components/MasterChecklistItemDetail';
import qs from 'qs';

const createModel = (
  fragment?: MasterChecklistItemDetailFragment,
): IMasterChecklistItemDetail => {
  return {
    responsibleId: fragment?.responsibleUser.id ?? null,
    comment: fragment?.comment ?? '',
    description: fragment?.description ?? '',
    dueDaysAfterClose: fragment?.dueDaysAfterClose ?? null,
    ignoreFromMonth: fragment?.ignoreFromMonth ?? null,
    ignoreFromYear: fragment?.ignoreFromYear ?? null,
    ignoreUntilMonth: fragment?.ignoreUntilMonth ?? null,
    ignoreUntilYear: fragment?.ignoreUntilYear ?? null,
    jeTypeLookupId: fragment?.jeType.id ?? null,
    jeFrequencyLookupId: fragment?.jeFrequency.id ?? null,
    journalId: fragment?.journalId ?? '',
    kbsEntityLookupId: fragment?.kbsEntity.id ?? null,
    kbsGroupLookupId: fragment?.kbsGroup.id ?? null,
    lastExecutedDate: fragment?.lastExecutedDate ?? null,
    numberOfOccurrences: fragment?.numberOfOccurrences ?? null,
    retireMonth: fragment?.retireMonth ?? null,
    retireYear: fragment?.retireYear ?? null,
    sendReminder: fragment?.sendReminder ?? false,
    startMonth: fragment?.startMonth ?? null,
    startYear: fragment?.startYear ?? null,
    title: fragment?.title ?? '',
  };
};

export const MasterChecklistItem = (): JSX.Element => {
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const location = useLocation();

  const [detail, setDetail] = useState<IMasterChecklistItemDetail | null>(null);
  const client = useRef<DefaultClient<unknown>>(useApolloClient().client);

  const [modalAlert, setModalAlert] = useState<React.ReactNode | null>(null);
  const { apolloError, confirm, error, success } =
    useModalAlerts(setModalAlert);

  const { clone } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const cloneId =
    typeof clone === 'string' && clone ? parseInt(clone) : undefined;

  useEffect(() => {
    if (params.id && !isNaN(parseInt(params.id))) {
      client.current
        .query<
          MasterChecklistItemDetailQuery,
          MasterChecklistItemDetailQueryVariables
        >({
          query: MasterChecklistItemDetailDocument,
          variables: {
            id: Number(params.id),
          },
          fetchPolicy: 'no-cache',
        })
        .then((result) => {
          if (!!result.data.masterChecklistItem) {
            setDetail(createModel(result.data.masterChecklistItem));
          } else {
            error({
              message: 'Unable to find the requested item',
            });
          }
        });
    } else if (cloneId) {
      client.current
        .query<
          MasterChecklistItemDetailQuery,
          MasterChecklistItemDetailQueryVariables
        >({
          query: MasterChecklistItemDetailDocument,
          variables: {
            id: Number(cloneId),
          },
          fetchPolicy: 'no-cache',
        })
        .then((result) => {
          if (!!result.data.masterChecklistItem) {
            setDetail({
              ...createModel(result.data.masterChecklistItem),
              journalId: '',
              lastExecutedDate: null,
            });
          } else {
            error({
              message: 'Unable to find the requested item to clone',
            });
          }
        });
    } else {
      setDetail(createModel());
    }
  }, [params.id]);

  const handleChange = (detail: IMasterChecklistItemDetail) => {
    setDetail(detail);
  };

  const handleSave = async (
    applyChangesToIncompleteJournalEntriesInOpenPeriods: boolean,
  ) => {
    const input: MasterChecklistItemInput = {
      responsibleUserId: detail?.responsibleId,
      comment: detail?.comment,
      description: detail?.description,
      dueDaysAfterClose: detail?.dueDaysAfterClose,
      ignoreFromMonth: detail?.ignoreFromMonth,
      ignoreFromYear: detail?.ignoreFromYear,
      ignoreUntilMonth: detail?.ignoreUntilMonth,
      ignoreUntilYear: detail?.ignoreUntilYear,
      jeTypeLookupId: detail?.jeTypeLookupId,
      jeFrequencyLookupId: detail?.jeFrequencyLookupId,
      journalId: detail?.journalId,
      kbsEntityLookupId: detail?.kbsEntityLookupId,
      kbsGroupLookupId: detail?.kbsGroupLookupId,
      numberOfOccurrences: detail?.numberOfOccurrences,
      retireMonth: detail?.retireMonth,
      retireYear: detail?.retireYear,
      sendReminder: detail?.sendReminder,
      startMonth: detail?.startMonth,
      startYear: detail?.startYear,
      title: detail?.title,
      applyChangesToIncompleteJournalEntriesInOpenPeriods,
    };

    const onError = (err: any) => {
      apolloError({ error: err });
    };

    const mutate = () => {
      if (!!params.id) {
        return client.current
          .mutate<
            UpdateMasterChecklistItemMutation,
            UpdateMasterChecklistItemMutationVariables
          >({
            mutation: UpdateMasterChecklistItemDocument,
            variables: {
              id: Number(params.id),
              input,
            },
          })
          .then(() => {
            success({
              message: 'Item updated',
              timeout: 1500,
            });
          })
          .catch((error) => {
            onError(error);
            throw error;
          });
      } else {
        return client.current
          .mutate<
            CreateMasterChecklistItemMutation,
            CreateMasterChecklistItemMutationVariables
          >({
            mutation: CreateMasterChecklistItemDocument,
            variables: {
              input,
            },
          })
          .then((response) => {
            if (cloneId) {
              history.replace(
                `${location.pathname}/${response.data?.createMasterChecklistItem.id}`,
              );
            } else {
              history.push(
                `${location.pathname}/${response.data?.createMasterChecklistItem.id}`,
              );
            }
            success({
              message: 'Item created',
              timeout: 1500,
            });
          })
          .catch((error) => {
            onError(error);
            throw error;
          });
      }
    };

    if (applyChangesToIncompleteJournalEntriesInOpenPeriods) {
      let resolve: () => void;
      let reject: (reason?: any) => void;

      const result = new Promise<void>((res, rej) => {
        resolve = res;
        reject = rej;
      });

      confirm({
        message:
          'Changes will be applied to currently open periods.  Are you sure you would like to do this?',
        onConfirm: () => {
          mutate()
            .then(resolve)
            .catch((error) => {
              onError(error);
            });
        },
        onCancel: () => {
          reject('cancel');
        },
      });

      return result;
    } else {
      return mutate();
    }
  };

  const handleCancel = () => {
    history.push(`/master-checklist`);
  };

  return (
    <Page title="Journal Entry Master">
      {modalAlert}
      {!!detail && (
        <MasterChecklistItemDetail
          detail={detail}
          onChange={handleChange}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}
    </Page>
  );
};

export default MasterChecklistItem;
