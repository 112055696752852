import { LookupSelect } from 'components/Lookups/LookupSelect';
import React from 'react';
import { LookupModel } from 'types/graphql';
import { JeFrequencyLookupWrapper } from 'components/Lookups/JeFrequencyLookups/JeFrequencyLookupWrapper';

interface IProps {
  includeDeleted?: boolean;
  selectedId?: number | null;
  onSelect: (item?: LookupModel) => void;
}

export const JeFrequencySelect = (props: IProps): JSX.Element => {
  return (
    <JeFrequencyLookupWrapper includeDeleted={props.includeDeleted}>
      {({ queryDocument, queryVariables, extractLookups }) => (
        <LookupSelect
          extractLookupItems={extractLookups}
          queryDocument={queryDocument}
          queryVariables={queryVariables}
          selectedId={props.selectedId}
          onSelect={props.onSelect}
        />
      )}
    </JeFrequencyLookupWrapper>
  );
};
